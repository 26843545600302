import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { colors, types } from "../../../../libs";
import DatePicker from "react-datepicker";

export const GenerateModal = styled(Modal)`
    .modal-width {
        max-width: 35%;
    }
    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    background-color: ${colors.background.lightBlack};
    overflow-y: auto;
`;

export const ModalTitle = styled.div`
    ${types.body.secondaryTitle}
    color: ${colors.text.white};
    font-weight: bold;
`;

export const ModalSubtitle = styled.div`
    ${types.body.normal}
`;

export const Spacer = styled.div`
    height: 20px;
    background-color: ${colors.background.lightBlack};
`;

export const CustomDatePicker = styled(DatePicker)`
    width: 100%;
    padding: 15px;
    border-radius: 0.25rem;
    background: #1e2025;
    outline: none;
    border: 1px solid transparent;
    color: #fff;

    &.error {
        border: 1px solid red;
    }
`;

export const DatePickerWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${colors.background.formField};
`;

export const DatePickerLabel = styled.div`
    ${types.body.normal}
    color: ${colors.text.white};
    opacity: 0.3;
    padding-top: 5px;
    padding-left: 15px;
`;

export const DiscountPeriodWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
`;

export const SwitchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
`;

export const SwitchLabel = styled.div`
    ${types.body.label}
    color: ${colors.text.disabled};
    padding: 0 5px;
`;
