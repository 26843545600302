import {
    ICastRequestDto,
    IFilmFestivalFormValues,
    IFilmFestivalMediaRequestDto,
    IOngoingUploads,
    ISponsorsRequestDto,
    IStaticFilePaths,
    ISubtitleRequestDto,
    IThumbnailType,
    IUploadCategory,
    IVideoType,
} from "../../../../libs";
import { vodService } from "../../../../services";

export const buildFilmFestivalUploadRequestDto = (
    values: IFilmFestivalFormValues,
    staticFilePaths: IStaticFilePaths[],
    mediaId: string,
    edit: boolean = false,
): IFilmFestivalMediaRequestDto | undefined => {
    let thumbnails: IThumbnailType | undefined;
    const {
        details,
        languageDetails,
        castDetails,
        sponsorsDetails: sponsorDetails,
        visibilityStatus,
    } = values;

    // get static assets uploaded paths
    const thumbnailStaticPaths = staticFilePaths.find(
        (item) => item.category === "thumbnails",
    )?.paths[0];

    if (thumbnailStaticPaths) {
        thumbnails = thumbnailStaticPaths;
    }

    let video: IVideoType | undefined;
    const videoStaticPaths = staticFilePaths.find(
        (item) => item.category === "video",
    )?.paths[0];

    if (videoStaticPaths) {
        video = videoStaticPaths;
    }

    let sponsorsRequest: ISponsorsRequestDto[] = [];
    const sponsors = staticFilePaths.find(
        (item) => item.category === "sponsors",
    )?.paths;
    if (sponsors) {
        sponsorDetails?.forEach((item, index) => {
            sponsorsRequest.push({
                id: item.id,
                s3Key:
                    sponsors.find((el) => el.fileName === item.file.file.name)
                        ?.s3Key || "",
                fileName: item.file.file.name,
            });
        });
    }

    let subtitles: ISubtitleRequestDto[] = [];

    if (languageDetails?.subtitles) {
        const subtitleStaticPaths = staticFilePaths.find(
            (item) => item.category === "subtitles",
        )?.paths;
        if (subtitleStaticPaths) {
            languageDetails.subtitles.forEach((item, index) => {
                if (item.file) {
                    subtitles.push({
                        id: item.id,
                        s3Key:
                            subtitleStaticPaths.find(
                                (el) => el.fileName === item.file?.file.name,
                            )?.s3Key || "",
                        fileName: item.file.file.name,
                        language: item.language,
                    });
                    return;
                }

                if (item.uploadedFile) {
                    subtitles.push({
                        id: item.id,
                        s3Key: item.uploadedFile.s3Key || "",
                        fileName: item.uploadedFile.fileName,
                        language: item.language,
                    });
                }
            });
        }
    }

    let castRequest: ICastRequestDto[] = [];

    if (castDetails) {
        const castFileStaticPaths = staticFilePaths.find(
            (item) => item.category === "cast",
        )?.paths;
        if (castFileStaticPaths) {
            castDetails.forEach((item, index) => {
                castRequest.push({
                    id: item.id,
                    name: item.name,
                    role: item.role,
                    fileName: item.file?.file.name || "",
                    s3Key:
                        castFileStaticPaths.find(
                            (el) => el.fileName === item.file?.file.name,
                        )?.s3Key || "",
                });
            });
        }
    }

    if (details && languageDetails && castRequest && visibilityStatus && edit) {
        const filmFestivalRequestDto: IFilmFestivalMediaRequestDto = {
            title: details.title,
            description: details.description,
            directors: details.directors,
            producers: details.producers,
            year: details.year,
            genre: details.genre,
            audience: details.audience,
            planId: details.planId,
            language: languageDetails.language,
            status: visibilityStatus,
            mediaId: mediaId,
            category: IUploadCategory.FILM_FESTIVAL,

            subtitles: subtitles,
            thumbnails: { userUploaded: thumbnails },
            video: {
                source: {
                    s3Key: "",
                    fileName: "",
                },
            },
        };

        return filmFestivalRequestDto;
    }

    // create request dto
    if (
        details &&
        languageDetails &&
        castRequest &&
        visibilityStatus &&
        video
    ) {
        const filmFestivalRequestDto: IFilmFestivalMediaRequestDto = {
            title: details.title,
            description: details.description,
            directors: details.directors,
            producers: details.producers,
            year: details.year,
            genre: details.genre,
            playlistId: details.playlistId,
            audience: details.audience,
            planId: details.planId,
            language: languageDetails.language,
            status: visibilityStatus,
            category: IUploadCategory.FILM_FESTIVAL,
            mediaId: mediaId,

            subtitles: subtitles,
            thumbnails: {
                userUploaded: thumbnails,
            },
            video: {
                source: video,
            },
        };

        return filmFestivalRequestDto;
    }
};

export const executeFilmFestivalUpload = async (upload: IOngoingUploads) => {
    if (upload.type === IUploadCategory.FILM_FESTIVAL) {
        if (upload.filesToUpload && upload.uploadedFiles) {
            const uploadedCount = upload.uploadedFiles.reduce((count, obj) => {
                count += obj.paths.length;
                return count;
            }, 0);

            const actualCountToUpload = upload.filesToUpload.reduce(
                (count, obj) => {
                    count += obj.files.length;
                    return count;
                },
                0,
            );

            if (
                actualCountToUpload > 0 &&
                uploadedCount === actualCountToUpload &&
                upload.formDataFilmFestival
            ) {
                const requestDto = buildFilmFestivalUploadRequestDto(
                    upload.formDataFilmFestival,
                    upload.uploadedFiles,
                    upload.mediaId,
                );
                if (requestDto) {
                    await vodService.createFilmFestivalMediaItem(requestDto);
                    return true;
                }
            }
        }
    }

    return false;
};
