import React from "react";
import * as Styled from "./ManageAccessToken.styled";
import { paymentService } from "../../../services";
import DataTable, { createTheme } from "react-data-table-component";
import { BaseButton, IMemberPlan, ITrialTokenDetails } from "../../../libs";
import { Col, Row, Spinner } from "react-bootstrap";
import { CreateAccessTokenModal } from "./create-access-token-modal";
import { Clipboard } from "react-bootstrap-icons";
import { toast } from "react-toastify";

export const ManageAccessToken: React.FC = () => {
    const [accessTokens, setAccessTokens] = React.useState<
        ITrialTokenDetails[]
    >([]);
    const [showCreateModal, setShowCreateModal] = React.useState(false);

    const getAccessTokens = React.useCallback(async () => {
        const response = await paymentService.getFreeTrialAccessTokens();
        console.log(response);
        setAccessTokens(response);
    }, []);

    const [loading, setLoading] = React.useState(true);

    const columns = [
        {
            name: "Trial Id",
            cell: (row: ITrialTokenDetails) => (
                <Styled.TrialIDWrapper
                    onClick={() => {
                        navigator.clipboard.writeText(row.trialId);
                        toast.success("Trial ID copied to clipboard!");
                    }}
                >
                    <span>{row.trialId}</span>
                    <Clipboard
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                    />
                </Styled.TrialIDWrapper>
            ),
        },
        {
            name: "Name",
            selector: (row: ITrialTokenDetails) => row.name,
            sortable: true,
        },
        {
            name: "Description",
            selector: (row: ITrialTokenDetails) => row.description,
        },
        {
            name: "Created Date",
            selector: (row: ITrialTokenDetails) =>
                new Date(row.timestamp).toLocaleDateString(),
            sortable: true,
        },
        {
            name: "Expired Date",
            selector: (row: ITrialTokenDetails) =>
                new Date(row.expiredDate).toLocaleDateString(),
            sortable: true,
        },
        {
            name: "Is Full Access",
            selector: (row: ITrialTokenDetails) =>
                row.isFullAccess ? "Yes" : "No",
            sortable: true,
        },
        {
            name: "Plans",
            wrap: true,
            selector: (row: ITrialTokenDetails) => {
                return row.planId
                    .map((planId) => {
                        return (
                            planOptions.find((plan) => plan.value === planId)
                                ?.label || "Uknown Plan"
                        );
                    })
                    .join(", ");
            },
        },
        {
            name: "Is Enabled",
            selector: (row: ITrialTokenDetails) =>
                row.isEnabled ? "Yes" : "No",
            sortable: true,
        },
        {
            name: "Usage",
            selector: (row: ITrialTokenDetails) =>
                `${row.currentUsage}/${row.usageLimit}`,
        },
    ];

    const [planOptions, setPlanOptions] = React.useState<
        { label: string; value: string }[]
    >([]);

    const fetchPlans = React.useCallback(async () => {
        const plans = await paymentService.getCustomerProductPlans();
        setPlanOptions(
            plans.map((plan: IMemberPlan) => ({
                label: plan.name,
                value: plan.id,
            })),
        );
        setLoading(false);
    }, []);

    React.useEffect(() => {
        getAccessTokens();
        fetchPlans();
    }, [getAccessTokens, fetchPlans]);

    createTheme("dark", {
        background: {
            default: "transparent",
        },
    });

    return (
        <Styled.ManageAccessTokenWrapper
            fluid
            style={{ height: loading ? "80vh" : "auto" }}
        >
            <Row>
                <Col xl={10} md={8}>
                    <Styled.Header>Manage Access Tokens</Styled.Header>
                </Col>
                <Col xl={2} md={4} className="m-auto">
                    <BaseButton
                        onClick={() => {
                            setShowCreateModal(true);
                        }}
                    >
                        Create Access Token
                    </BaseButton>
                </Col>
            </Row>
            {!loading ? (
                <DataTable
                    columns={columns}
                    data={accessTokens}
                    pagination={true}
                    paginationPerPage={10}
                    theme="dark"
                />
            ) : (
                <Styled.StyledTableOverlay>
                    <Spinner animation="border" role="status" />
                </Styled.StyledTableOverlay>
            )}
            <CreateAccessTokenModal
                show={showCreateModal}
                onHide={() => {
                    setLoading(true);
                    setShowCreateModal(false);
                    getAccessTokens();
                    setLoading(false);
                }}
                planOptions={planOptions}
            />
        </Styled.ManageAccessTokenWrapper>
    );
};
