import axios from "axios";
import {
  API_BASE_PATHS,
  API_ROUTES,
  IFreeTrialAccessTokenRequest,
  IMemberPlan,
  IMemberPlanRequest,
  ITrialTokenDetails,
  ITrialUserDetails,
} from "../libs";
import { buildRequestConfig } from "./buildRequestConfig";

class PaymentService {
  async getStripeCustomerAccountDetails(): Promise<any> {
    const config = await buildRequestConfig();

    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PAYMENTS_API}/${API_ROUTES.GET_ACCOUNT_DETAILS}`,
      { ...config }
    );
    return response.data;
  }

  async createStripeCustomerAccount(): Promise<any> {
    const config = await buildRequestConfig();

    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PAYMENTS_API}/${API_ROUTES.CREATE_ACCOUNT}`,
      { ...config }
    );
    return response.data;
  }

  async updateStripeCustomerAccount(
    accountId: string,
    bankToken: string
  ): Promise<any> {
    const config = await buildRequestConfig();

    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PAYMENTS_API}/${API_ROUTES.UPDATE_ACCOUNT}`,
      { accountId, bankToken },
      { ...config }
    );
    return response.data;
  }

  async saveStripeCustomerAccountDetails(accountId: string): Promise<any> {
    const config = await buildRequestConfig();

    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PAYMENTS_API}/${API_ROUTES.SAVE_ACCOUNT}/${accountId}`,
      null,
      { ...config }
    );
    return response.data;
  }

  async createCustomerProductPlan(planData: IMemberPlanRequest): Promise<any> {
    const config = await buildRequestConfig();

    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PAYMENTS_API}/${API_ROUTES.MEMBER_PLANS}`,
      planData,
      { ...config }
    );
    return response.data;
  }

  async updateCustomerProductPlan(
    planId: string,
    planData: IMemberPlanRequest
  ): Promise<any> {
    const config = await buildRequestConfig();

    const response = await axios.put(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PAYMENTS_API}/${API_ROUTES.MEMBER_PLANS}/${planId}`,
      planData,
      { ...config }
    );
    return response.data;
  }

  async deleteCustomerProductPlan(planId: string): Promise<any> {
    const config = await buildRequestConfig();

    const response = await axios.delete(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PAYMENTS_API}/${API_ROUTES.MEMBER_PLANS}/${planId}`,
      { ...config }
    );
    return response.data;
  }

  async getCustomerProductPlans(): Promise<IMemberPlan[]> {
    const config = await buildRequestConfig();

    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PAYMENTS_API}/${API_ROUTES.MEMBER_PLANS}`,
      { ...config }
    );

    return response.data.data;
  }

  async getUpdateAccountLink(accountId: string): Promise<any> {
    const config = await buildRequestConfig();

    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PAYMENTS_API}/${API_ROUTES.GET_UPDATE_ACCOUNT_LINK}/${accountId}`,
      { ...config }
    );
    return response.data;
  }

  async createFreeTrialAccessToken(data: IFreeTrialAccessTokenRequest): Promise<any> {
    const config = await buildRequestConfig();

    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PAYMENTS_API}/${API_ROUTES.FREE_TRIAL_ACCESS_TOKEN}`,
      data,
      { ...config }
    );
    return response.data;
  }

  async getFreeTrialAccessTokens(): Promise<ITrialTokenDetails[]> {
    const config = await buildRequestConfig();

    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PAYMENTS_API}/${API_ROUTES.GET_FREE_TRIAL_ACCESS_TOKENS}`,
      { ...config }
    );
    return response.data.data.trialCodelist;
  }

  async getFreeTrialUsers(): Promise<ITrialUserDetails> {
    const config = await buildRequestConfig();

    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/${API_BASE_PATHS.PAYMENTS_API}/${API_ROUTES.GET_FREE_TRIAL_USERS}`,
      { ...config }
    );
    return response.data.data;
  }
}

export const paymentService = new PaymentService();
