//import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTEPATHS } from "./libs";
import ScrollToTop from "./components/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.scss";

import {
    ChannelDetails,
    Footer,
    NavigationContainer,
    Recordings,
    SidebarLayout,
} from "./components";

import {
    AboutPage,
    AdServer,
    BlockPage,
    ContactPage,
    DashboardPage,
    LandingPage,
    LiveChannelsContent,
    LoginPage,
    ManageStoragePage,
    PricingPage,
    RegistrationPage,
    RoadmapPage,
    RouteAdminContainer,
    RouteConfirmContainer,
    TeamPage,
    VodUploads,
    ManageLibrariesPage,
} from "./pages";
import { PlaylistsPage } from "./pages/route-playlists";
import { RouteManagePaymentContainer } from "./pages/route-manage-payments/RouteManageContainer";

function App() {
    return (
        <>
            <ScrollToTop />
            {/* Main Navigation */}
            <header id="header" className="fixed-top">
                <div className="container-lg d-flex align-items-center">
                    <Routes>
                        <Route
                            path={ROUTEPATHS.HOME}
                            element={<NavigationContainer />}
                        />
                        <Route
                            path={ROUTEPATHS.ABOUT}
                            element={<NavigationContainer />}
                        />
                        <Route
                            path={ROUTEPATHS.PRICING}
                            element={<NavigationContainer />}
                        />
                        <Route
                            path={ROUTEPATHS.ROADMAP}
                            element={<NavigationContainer />}
                        />
                        <Route
                            path={ROUTEPATHS.BLOCKCHAIN}
                            element={<NavigationContainer />}
                        />
                        <Route
                            path={ROUTEPATHS.TEAM}
                            element={<NavigationContainer />}
                        />
                        <Route
                            path={ROUTEPATHS.CONTACT}
                            element={<NavigationContainer />}
                        />
                    </Routes>
                </div>
            </header>

            {/* Main Content */}

            <main id="main" className="flex-shrink-0">
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    theme="dark"
                />
                <Routes>
                    <Route path={ROUTEPATHS.HOME} element={<LandingPage />} />
                    <Route path={ROUTEPATHS.ABOUT} element={<AboutPage />} />
                    <Route
                        path={ROUTEPATHS.PRICING}
                        element={<PricingPage />}
                    />
                    <Route
                        path={ROUTEPATHS.ROADMAP}
                        element={<RoadmapPage />}
                    />
                    <Route
                        path={ROUTEPATHS.BLOCKCHAIN}
                        element={<BlockPage />}
                    />
                    <Route path={ROUTEPATHS.TEAM} element={<TeamPage />} />
                    <Route
                        path={ROUTEPATHS.CONTACT}
                        element={<ContactPage />}
                    />
                    <Route
                        path={ROUTEPATHS.CONFIRM_USER}
                        element={<RouteConfirmContainer />}
                    />

                    <Route
                        path={ROUTEPATHS.REGISTER}
                        element={<RegistrationPage />}
                    />
                    <Route path={ROUTEPATHS.LOGIN} element={<LoginPage />} />

                    {/* Authenticated Routes */}

                    <Route element={<SidebarLayout />}>
                        <Route
                            path={ROUTEPATHS.DASHBOARD}
                            element={<DashboardPage />}
                        />
                        <Route path={ROUTEPATHS.VOD} element={<VodUploads />} />
                        <Route
                            path={ROUTEPATHS.AD_SERVER}
                            element={<AdServer />}
                        />

                        <Route path={ROUTEPATHS.LIVE_CHANNELS}>
                            <Route index element={<LiveChannelsContent />} />
                            <Route path=":id" element={<ChannelDetails />} />
                        </Route>
                        <Route
                            path={ROUTEPATHS.RECORDINGS}
                            element={<Recordings />}
                        />
                        <Route
                            path={`${ROUTEPATHS.MANAGE_PAYMENTS_ROUTE}/*`}
                            element={<RouteManagePaymentContainer />}
                        />
                        <Route
                            path={ROUTEPATHS.MANAGE_STORAGE}
                            element={<ManageStoragePage />}
                        />
                        <Route
                            path={ROUTEPATHS.MANAGE_LIBRARIES}
                            element={<ManageLibrariesPage />}
                        />
                        <Route
                            path={ROUTEPATHS.PLAYLISTS}
                            element={<PlaylistsPage />}
                        />
                        <Route
                            path={`${ROUTEPATHS.ADMIN}/*`}
                            element={<RouteAdminContainer />}
                        />
                    </Route>

                    <Route
                        path="*"
                        element={<Navigate to={ROUTEPATHS.HOME} />}
                    />
                </Routes>
            </main>

            {/* Main Footer */}
            <Routes>
                <Route path={ROUTEPATHS.HOME} element={<Footer />} />
                <Route path={ROUTEPATHS.ABOUT} element={<Footer />} />
                <Route path={ROUTEPATHS.PRICING} element={<Footer />} />
                <Route path={ROUTEPATHS.ROADMAP} element={<Footer />} />
                <Route path={ROUTEPATHS.BLOCKCHAIN} element={<Footer />} />
                <Route path={ROUTEPATHS.TEAM} element={<Footer />} />
                <Route path={ROUTEPATHS.CONTACT} element={<Footer />} />
            </Routes>
        </>
    );
}

export default App;
