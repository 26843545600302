import React from "react";
import { Dropdown } from "react-bootstrap";

import {
  DropdownButton,
  MenuList,
  bytesToHumanReadableSize,
} from "../../../libs";

import * as Styled from "./Sidemenu.styled";

import { IUploadType } from "../../upload-modal/types";

import { useGlobalState } from "../../../behavioral";

interface ISideMenuProps {
  onClickUploadVideo: (uploadType: IUploadType) => void;
}

export const DashboardSideMenu: React.FC<ISideMenuProps> = ({
  onClickUploadVideo,
}) => {
  const uploadVideoHandler = React.useCallback(() => {
    onClickUploadVideo(IUploadType.FILM_FESTIVAL_VIDEO);
  }, [onClickUploadVideo]);

  const uploadGeneralVideoHandler = React.useCallback(() => {
    onClickUploadVideo(IUploadType.GENERAL_VIDEO);
  }, [onClickUploadVideo]);

  const liveStreamHandler = React.useCallback(() => {}, []);

  const { state } = useGlobalState();

  const menuList = [
    {
      itemText: "Dashboard",
      linkPath: "/dashboard",
    },
    {
      itemText: "Video-On-Demand",
      linkPath: "/vod-uploads",
    },
    {
      itemText: "Playlists",
      linkPath: "/playlists",
    },
    {
      itemText: "Live Stream",
      children: [
        {
          itemText: "Channels",
          linkPath: "/live-channels",
        },
        {
          itemText: "Recordings",
          linkPath: "/recordings",
        },
      ],
    },
    {
      itemText: "Ad Server",
      linkPath: "/ad-server",
    },
    {
      itemText: "Manage Payments",
      children: [
        {
          itemText: "Payments and Plans",
          linkPath: "/manage-payments/payment-plans",
        },
        {
          itemText: "Access Tokens",
          linkPath: "/manage-payments/manage-access-tokens",
        },
      ],
    },
    {
      itemText: "Manage Storage",
      linkPath: "/manage-storage",
    },
    {
      itemText: "Manage Libraries",
      linkPath: "/manage-libraries",
    }
  ];

  if (state.isAdmin) {
    menuList.push({
      itemText: "Admin",
      children: [
        {
          itemText: "Manage Users",
          linkPath: "/admin/manage-users",
        },
        {
          itemText: "Manage Packages",
          linkPath: "/admin/manage-packages",
        },
      ],
    });
  }

  const usedPercentage = React.useMemo(() => {
    return state.storage.limit > 0
      ? (state.storage.used / state.storage.limit) * 100
      : 0;
  }, [state.storage.limit, state.storage.used]);

  return (
    <>
      <Styled.SideMenuUploadCta>
        <DropdownButton>
          <Dropdown.Item onClick={uploadGeneralVideoHandler}>
            Upload a General Video
          </Dropdown.Item>
          <Dropdown.Item onClick={uploadVideoHandler}>
            Upload a Film Festival Video
          </Dropdown.Item>
          <Dropdown.Item onClick={liveStreamHandler}>
            Start Live Streaming
          </Dropdown.Item>
        </DropdownButton>
      </Styled.SideMenuUploadCta>
      <Styled.SideMenuListWrapper>
        <MenuList menuList={menuList} />
      </Styled.SideMenuListWrapper>
      <Styled.SideMenuUpgradeCard>
        {usedPercentage > 70 && (
          <Styled.UpgradeHeader>Need more from Cemboo?</Styled.UpgradeHeader>
        )}
        <Styled.UsageWrapper>
          <Styled.UsageProgressBar now={usedPercentage} variant="success" />
          <Styled.UsageText>
            {bytesToHumanReadableSize(state.storage.used)} of{" "}
            {bytesToHumanReadableSize(state.storage.limit)} used
          </Styled.UsageText>
        </Styled.UsageWrapper>
        <Styled.UpgradeCtaLink>
          Upgrade to next level <i className="fas fa-arrow-right"></i>
        </Styled.UpgradeCtaLink>
      </Styled.SideMenuUpgradeCard>
    </>
  );
};
