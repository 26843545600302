import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import * as Yup from "yup";

import {
    BaseButton,
    Error,
    FileWithPreview,
    IAudience,
    RadioInput,
    SelectInput,
    TextAreaInput,
    TextInput,
    IFilmFestivalDetails,
    IMemberPlan,
    IPlanType,
} from "../../../../../libs";
import Select from "react-select";

import { ThumbnailImagesSection } from "./thumbnail-images";

import { useFormik } from "formik";
import { genreOptions } from "../../../../mocks";
import * as Styled from "./Details.styled";

export const DetailsSection: React.FC<{
    onDetailsSubmit: (details: IFilmFestivalDetails) => void;
    plans: IMemberPlan[];
    details?: IFilmFestivalDetails;
}> = ({ onDetailsSubmit, details, plans }) => {
    const [thumbnailImage, setThumbnailImage] =
        React.useState<FileWithPreview>();

    const handleThumbnailImageUpload = React.useCallback(
        (thumbnailImage: FileWithPreview) => {
            setThumbnailImage(thumbnailImage);
        },
        [],
    );

    const handleThumbnailDelete = React.useCallback(() => {
        setThumbnailImage(undefined);
    }, []);

    const [formattedPlanOptions, setFormattedPlanOptions] = React.useState<
        { label: string; value: string }[]
    >([]);

    const formik = useFormik({
        initialValues: {
            title: details?.title || "",
            description: details?.description || "",
            directors: details?.directors.join(",") || "",
            genre: details?.genre?.join(",") || "",
            imdbLink: details?.imdbLink || "",
            producers: details?.producers?.join(",") || "",
            year: String(details?.year) || "",
            audience: details?.audience || IAudience.GENERAL,
            playlistId: undefined,
            planId: details?.planId || [],
        },

        validationSchema: Yup.object({
            title: Yup.string().required(
                "Please enter a title for the media item",
            ),
            directors: Yup.string().required(
                "Please enter directors for the media",
            ),
            producers: Yup.string().required(
                "Please enter producers for the media",
            ),
            genre: Yup.string().required(
                "Please select the genre for the media",
            ),
        }),

        onSubmit(values, formikHelpers) {
            const filmFestivalDetails = {
                ...values,
                genre: values.genre.split(","),
                producers: values.producers.split(","),
                directors: values.directors.split(","),
                thumbnails: thumbnailImage,
            };
            onDetailsSubmit(filmFestivalDetails);
        },
    });

    useEffect(() => {
        setFormattedPlanOptions(
            plans
                .filter(
                    (el) =>
                        el.isEnabled &&
                        el.isPublic &&
                        el.active &&
                        (el.planType === IPlanType.VOD ||
                            formik.values.planId?.includes(el.id)), // If a non VOD plan is already selected, then show it in the dropdown
                )
                .map((item: IMemberPlan) => ({
                    value: item.id,
                    label: `${item.name} -  $${item.amount
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`,
                })),
        );
    }, [formik.values.planId, plans]);

    const [yearList, setYearList]: any[] = useState([]);

    useEffect(() => {
        // generate year list options (starting from 1900 to current)
        let _years: any[] = [];
        let max = new Date().getFullYear();
        let min = 1900;

        while (max >= min) {
            _years.push({ id: max, value: max, label: max });
            max -= 1;
        }

        setYearList(_years);
    }, [setYearList]);

    return (
        <>
            <Styled.DetailsHeader>Details</Styled.DetailsHeader>
            <Form onSubmit={formik.handleSubmit}>
                <Styled.RequiredText>* indicates required</Styled.RequiredText>
                <Styled.FormFieldRow>
                    <Col>
                        <TextInput
                            fieldName="title"
                            fieldLabel="Title *"
                            placeholder="Add a title for the video"
                            fieldValue={formik.values.title}
                            onChangeHandler={formik.handleChange}
                            onBlurHandler={formik.handleBlur}
                            hasError={
                                formik.touched.title && !!formik.errors.title
                            }
                        />
                        {formik.touched.title && formik.errors.title && (
                            <Error message={formik.errors.title} />
                        )}
                    </Col>
                </Styled.FormFieldRow>
                <Styled.FormFieldRow>
                    <Col>
                        <TextAreaInput
                            fieldName="description"
                            fieldLabel="Description"
                            placeholder="Tell viewers what the video is about"
                            fieldValue={formik.values.description}
                            onChangeHandler={formik.handleChange}
                            onBlurHandler={formik.handleBlur}
                        />
                    </Col>
                </Styled.FormFieldRow>
                <Styled.FormFieldRow>
                    <Col>
                        <TextInput
                            fieldName="directors"
                            fieldLabel="Directors (use comma to separate values) *"
                            placeholder="Add all directors here"
                            fieldValue={formik.values.directors}
                            onChangeHandler={formik.handleChange}
                            onBlurHandler={formik.handleBlur}
                        />
                        {formik.touched.directors &&
                            formik.errors.directors && (
                                <Error message={formik.errors.directors} />
                            )}
                    </Col>
                </Styled.FormFieldRow>
                <Styled.FormFieldRow>
                    <Col>
                        <SelectInput
                            fieldName="year"
                            fieldLabel="Production Year *"
                            options={yearList}
                            fieldValue={formik.values.year}
                            onChangeHandler={formik.handleChange}
                            placeholder="Click here to select year"
                            menuHeight={190}
                        />
                        {formik.touched.year && formik.errors.year && (
                            <Error message={formik.errors.year} />
                        )}
                    </Col>
                </Styled.FormFieldRow>
                <Styled.ThumbnailSectionContainer>
                    <Styled.FormFieldRow>
                        <Col>
                            <Styled.ThumbnailHeader>
                                Thumbnail Images
                            </Styled.ThumbnailHeader>
                        </Col>
                    </Styled.FormFieldRow>
                    <Styled.FormFieldRow>
                        <Styled.ThumbnailText>
                            Select or upload an image that reflects the content
                            of the video. A good thumbnail stands out and
                            attracts the attention of users.
                        </Styled.ThumbnailText>
                    </Styled.FormFieldRow>
                    <ThumbnailImagesSection
                        onThumbnailUpload={handleThumbnailImageUpload}
                        onThumbnailDelete={handleThumbnailDelete}
                    />
                </Styled.ThumbnailSectionContainer>
                <Styled.AudienceSectionWrapper>
                    <Styled.AudienceHeader>Audience *</Styled.AudienceHeader>
                    <Styled.AudienceText>
                        Please tell us which audience your film is intended for
                    </Styled.AudienceText>
                    <Styled.AudienceRadioInputWrapper
                        direction="vertical"
                        gap={3}
                    >
                        <RadioInput
                            fieldLabel="G: General Audiences"
                            fieldName="audience"
                            fieldId="G"
                            checked={
                                formik.values.audience === IAudience.GENERAL
                            }
                            fieldValue={IAudience.GENERAL}
                            onChangeHandler={formik.handleChange}
                        />
                        <RadioInput
                            fieldId="PG"
                            fieldLabel="PG: Parental Guidance Suggested"
                            fieldName="audience"
                            checked={
                                formik.values.audience ===
                                IAudience.PARENTAL_GUIDANCE
                            }
                            fieldValue={IAudience.PARENTAL_GUIDANCE}
                            onChangeHandler={formik.handleChange}
                        />
                        <RadioInput
                            fieldId="PG-13"
                            fieldLabel="PG-13: Parental Strongly Cautioned"
                            fieldName="audience"
                            checked={
                                formik.values.audience ===
                                IAudience.STRICT_PARENTAL_GUIDANCE
                            }
                            fieldValue={IAudience.STRICT_PARENTAL_GUIDANCE}
                            onChangeHandler={formik.handleChange}
                        />
                        <RadioInput
                            fieldId="R"
                            fieldLabel="R: Restricted"
                            fieldName="audience"
                            checked={
                                formik.values.audience === IAudience.RESTRICTED
                            }
                            fieldValue={IAudience.RESTRICTED}
                            onChangeHandler={formik.handleChange}
                        />
                        <RadioInput
                            fieldId="NC-17"
                            fieldLabel="NC-17: Clearly Adult"
                            fieldName="audience"
                            checked={formik.values.audience === IAudience.ADULT}
                            fieldValue={IAudience.ADULT}
                            onChangeHandler={formik.handleChange}
                        />
                    </Styled.AudienceRadioInputWrapper>
                </Styled.AudienceSectionWrapper>

                <Styled.ShowMoreOrLessText>Show Less</Styled.ShowMoreOrLessText>
                <Styled.FormFieldRow>
                    <Col>
                        <TextInput
                            fieldLabel="Producer/s  (use commas between each) *"
                            fieldName="producers"
                            placeholder="Add all producers here separated by a COMMA"
                            fieldValue={formik.values.producers}
                            onChangeHandler={formik.handleChange}
                            onBlurHandler={formik.handleBlur}
                        />
                    </Col>
                </Styled.FormFieldRow>
                <Styled.FormFieldRow>
                    <Col>
                        <TextInput
                            fieldLabel="IMDB Link"
                            fieldName="imdbLink"
                            placeholder="Add link here"
                            fieldValue={formik.values.imdbLink}
                            onChangeHandler={formik.handleChange}
                            onBlurHandler={formik.handleBlur}
                        />
                    </Col>
                </Styled.FormFieldRow>
                <Styled.SelectFieldWrapper>
                    <Styled.SelectFieldHeader>Genre</Styled.SelectFieldHeader>
                    <Styled.SelectFieldText>
                        Use the the field below to add as many genres as nedded.{" "}
                    </Styled.SelectFieldText>
                    <Styled.FormFieldRow>
                        <Col>
                            <SelectInput
                                fieldName="genre"
                                options={genreOptions}
                                placeholder="Select Genre"
                                fieldValue={formik.values.genre}
                                onChangeHandler={formik.handleChange}
                                onBlurHandler={formik.handleBlur}
                                menuHeight={190}
                            />
                        </Col>
                        {formik.touched.genre && formik.errors.genre && (
                            <Error message={formik.errors.genre} />
                        )}
                    </Styled.FormFieldRow>
                </Styled.SelectFieldWrapper>
                <Styled.LinkPlanHeader>Link Plan</Styled.LinkPlanHeader>
                <Styled.FormFieldRow>
                    <Col>
                        <Select
                            className="custom-select"
                            isMulti
                            styles={{
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    background: "#1E2025",
                                    color: "#fff",
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    background: state.isFocused
                                        ? "#ccc"
                                        : "#1E2025",
                                    color: state.isFocused ? "#1E2025" : "#fff",
                                }),
                                singleValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "#fff",
                                }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    height: "50px",
                                    background: "#1E2025",
                                    color: "#fff",
                                    border: formik.errors.planId
                                        ? "1px solid red"
                                        : state.isFocused
                                          ? "1px solid transparent"
                                          : "1px solid transparent",
                                }),
                                multiValueRemove: (baseStyles) => ({
                                    ...baseStyles,
                                    color: "#000",
                                }),
                            }}
                            placeholder="Select Plans"
                            value={formattedPlanOptions.filter((plan) =>
                                formik.values.planId.includes(plan.value),
                            )}
                            isClearable
                            options={formattedPlanOptions}
                            onChange={(selectedOptions) => {
                                formik.setFieldValue(
                                    "planId",
                                    selectedOptions.map(
                                        (option) => option.value,
                                    ),
                                );
                            }}
                        />
                    </Col>
                </Styled.FormFieldRow>
                {/* <Styled.SelectFieldWrapper>
                    <Styled.SelectFieldHeader>
                        Playlists
                    </Styled.SelectFieldHeader>
                    <Styled.SelectFieldText>
                        Add this video to one or more playlists so that users
                        can discover your content more quickly.
                    </Styled.SelectFieldText>
                    <Styled.FormFieldRow>
                        <Col>
                            <SelectInput
                                fieldName="playlist"
                                options={playListOptions}
                                placeholder="Select Playlist"
                                fieldValue={formik.values.playlistId}
                                onChangeHandler={formik.handleChange}
                                onBlurHandler={formik.handleBlur}
                            />
                        </Col>
                    </Styled.FormFieldRow>
                </Styled.SelectFieldWrapper> */}
                <BaseButton
                    type="submit"
                    variant={!formik.isValid ? "secondary" : "primary"}
                    disabled={!formik.isValid}
                >
                    Continue
                </BaseButton>
            </Form>
        </>
    );
};
