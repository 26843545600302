import { Route, Routes } from "react-router-dom";
import { ROUTEPATHS } from "../../libs";
import { ManagePaymentsContainer } from "./manage-payments";
import { ManageAccessTokenContainer } from "./manage-access-token";

export const RouteManagePaymentContainer: React.FC = () => {
    return (
        <Routes>
            <Route
                path={`/${ROUTEPATHS.MANAGE_PAYMENTS_PLANS}`}
                element={<ManagePaymentsContainer />}
            />
            <Route
                path={`/${ROUTEPATHS.MANAGE_ACCESS_TOKENS}`}
                element={<ManageAccessTokenContainer />}
            />
        </Routes>
    );
};
