import React from "react";

import {
    IAudience,
    IMediaResponseDto,
    MultiPartUploader,
    PublishStatus,
    IFilmFestivalCastDetails,
    IFilmFestivalDetails,
    IFilmFestivalLanguageDetails,
    IFilmFestivalSponsorsDetails,
    IMemberPlan,
} from "../../../../../libs";
import { ContentTypes } from "../../../types";
import { UploadPreview } from "../../upload-preview";
import { CastDetails } from "./../cast";
import { DetailsSection } from "./../details";
import { LanguageDetails } from "./../language-and-cc";
import { SponsorDetails } from "./../sponsors";
import { VisibilityDetails } from "./../visibility";

import * as Styled from "./FilmFestivalEditModalBody.styled";
import { buildFilmFestivalUploadRequestDto } from "../FilmFestivalUploadUtils";
import { paymentService, vodService } from "../../../../../services";

interface IFilmFestivalEditModalBodyProps {
    mediaItem: IMediaResponseDto;
    contentType: ContentTypes;
    onContinueToNextSection: () => void;
    clientId: string;
    onUploadComplete: () => void;
}

export const FilmFestivalEditModalBody: React.FC<
    IFilmFestivalEditModalBodyProps
> = ({
    mediaItem,
    contentType = ContentTypes.UPLOAD_DETAILS,
    clientId,
    onUploadComplete,
    onContinueToNextSection,
}) => {
    // Film Festival each section information
    const [details, setDetails] = React.useState<IFilmFestivalDetails>({
        title: mediaItem.title,
        description: mediaItem.description,
        directors: mediaItem?.directors || [],
        producers: mediaItem?.producers || [],
        year: String(mediaItem.year),
        genre: mediaItem?.genre || [],
        audience: mediaItem?.audience || IAudience.GENERAL,
        planId: mediaItem?.planId || [],
    });
    const [languageDetails, setLanguageDetails] =
        React.useState<IFilmFestivalLanguageDetails>({
            language: mediaItem?.language || "",
            subtitles:
                mediaItem?.subtitles?.map((item) => ({
                    id: item.id,
                    language: item.language,
                    file: undefined,
                    uploadedFile: {
                        fileName: item.fileName,
                        previewUrl: item.cdnPath,
                        s3Key: item.s3Key,
                        s3Prefix: item.s3Prefix,
                    },
                })) || [],
        });
    const [castDetails, setCastDetails] =
        React.useState<IFilmFestivalCastDetails[]>();
    const [sponsorDetails, setSponsorDetails] =
        React.useState<IFilmFestivalSponsorsDetails[]>();
    const [visibilityStatus, setVisibilityStatus] =
        React.useState<PublishStatus>(mediaItem.status || PublishStatus.DRAFT);

    const [percentage, setPercentage] = React.useState(0);

    const [staticFilePaths, setStaticFilePaths] = React.useState<
        {
            category: string;
            paths: { s3Key: string; fileName: string }[];
        }[]
    >([]);

    const [filesToUpload, setFilesToUpload] = React.useState<
        {
            category: string;
            files: File[];
        }[]
    >([]);
    const [plans, setPlans] = React.useState<IMemberPlan[]>([]);

    const [allUploadStarted, setAllUploadStarted] = React.useState(false);

    const populatePlans = React.useCallback(async () => {
        const plansData = await paymentService.getCustomerProductPlans();
        setPlans(plansData);
    }, []);

    React.useEffect(() => {
        populatePlans();
        // eslint-disable-next-line
    }, []);

    const handleDetailsSubmit = React.useCallback(
        (details: IFilmFestivalDetails) => {
            setDetails(details);
            // Add any static assets here to list of files to upload
            if (details.thumbnails) {
                const thumbnailFile = details.thumbnails?.file;
                const newThumbnail = {
                    category: "thumbnails",
                    files: [thumbnailFile],
                };
                const existingThumbnailFileIndex = filesToUpload.findIndex(
                    (elem) => elem.category === "thumbnails",
                );
                if (existingThumbnailFileIndex >= 0) {
                    filesToUpload.splice(
                        existingThumbnailFileIndex,
                        1,
                        newThumbnail,
                    );
                    setFilesToUpload([...filesToUpload]);
                } else {
                    setFilesToUpload([...filesToUpload, newThumbnail]);
                }
            }
            onContinueToNextSection();
        },
        [onContinueToNextSection, filesToUpload],
    );

    const handleLanguageDetailsSubmit = React.useCallback(
        (languageDetails: IFilmFestivalLanguageDetails) => {
            setLanguageDetails(languageDetails);
            if (languageDetails.subtitles) {
                var subtitleFiles = languageDetails.subtitles
                    .filter((item) => item && item.file)
                    .map((item) => item.file)
                    .map((el) => el?.file) as File[];

                subtitleFiles = subtitleFiles.filter((el) => el !== undefined);

                const newSubtitles = {
                    category: "subtitles",
                    files: subtitleFiles,
                };
                const existingSubtitleFileIndex = filesToUpload.findIndex(
                    (elem) => elem.category === "subtitles",
                );
                if (existingSubtitleFileIndex >= 0) {
                    filesToUpload.splice(
                        existingSubtitleFileIndex,
                        1,
                        newSubtitles,
                    );
                    setFilesToUpload([...filesToUpload]);
                } else {
                    setFilesToUpload([...filesToUpload, newSubtitles]);
                }
            }
            onContinueToNextSection();
        },
        [onContinueToNextSection, filesToUpload],
    );

    const handleCastDetailsSubmit = React.useCallback(
        (castDetails: IFilmFestivalCastDetails[]) => {
            setCastDetails(castDetails);
            if (castDetails) {
                const castFiles = castDetails
                    .map((item) => item.file)
                    .map((el) => el?.file);
                let validCastFiles: File[] = [];
                castFiles.forEach((el) => {
                    if (el) {
                        validCastFiles.push(el);
                    }
                });
                if (castFiles) {
                    const existingCastFileIndex = filesToUpload.findIndex(
                        (elem) => elem.category === "cast",
                    );
                    const newCastFiles = {
                        category: "cast",
                        files: validCastFiles,
                    };
                    if (existingCastFileIndex >= 0) {
                        filesToUpload.splice(
                            existingCastFileIndex,
                            1,
                            newCastFiles,
                        );
                        setFilesToUpload([...filesToUpload]);
                    } else {
                        setFilesToUpload([...filesToUpload, newCastFiles]);
                    }
                }
            }
            onContinueToNextSection();
        },
        [onContinueToNextSection, filesToUpload],
    );

    const handleSponsorDetailsSubmit = React.useCallback(
        (sponsorDetails: IFilmFestivalSponsorsDetails[]) => {
            setSponsorDetails(sponsorDetails);
            if (sponsorDetails) {
                const sponsorLogos = sponsorDetails.map(
                    (sponsor) => sponsor.file.file,
                );
                const existingSponsorFileIndex = filesToUpload.findIndex(
                    (elem) => elem.category === "sponsors",
                );
                const newSponsorFiles = {
                    category: "sponsors",
                    files: sponsorLogos,
                };
                if (existingSponsorFileIndex >= 0) {
                    filesToUpload.splice(
                        existingSponsorFileIndex,
                        1,
                        newSponsorFiles,
                    );
                    setFilesToUpload([...filesToUpload]);
                } else {
                    setFilesToUpload([...filesToUpload, newSponsorFiles]);
                }
            }
            onContinueToNextSection();
        },
        [onContinueToNextSection, filesToUpload],
    );

    const uploadFilesToS3 = React.useCallback(() => {
        let filePaths: {
            category: string;
            paths: { s3Key: string; fileName: string }[];
        }[] = [];

        for (let index = 0; index < filesToUpload.length; index++) {
            const { files, category } = filesToUpload[index];
            console.log(`Uploading ${category} files now`);

            for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
                const file = files[fileIndex];
                const uploader = new MultiPartUploader({
                    fileName: file.name,
                    file: file,
                    clientId: clientId,
                    mediaId: mediaItem.mediaId,
                    category: category,
                });

                uploader
                    .getOnProgress(function (params: any) {
                        if (category === "video") {
                            setPercentage(params.percentage);
                        }
                    })
                    .getOnComplete(function (params: any) {
                        console.log(`Uploaded ${category} files`);
                        const groupIndex = filePaths.findIndex(
                            (elem) => elem.category === category,
                        );
                        const elem = filePaths[groupIndex];
                        if (elem) {
                            elem.paths.push({
                                s3Key: params.s3Key,
                                fileName: params.fileName,
                            });
                            filePaths[groupIndex] = elem;
                        } else {
                            filePaths.push({
                                category,
                                paths: [
                                    {
                                        s3Key: params.s3Key,
                                        fileName: params.fileName,
                                    },
                                ],
                            });
                        }
                        setStaticFilePaths([...filePaths]);
                    })
                    .getOnError((error: any) => {
                        console.log(error);
                    });

                uploader.start();
            }
        }
    }, [clientId, filesToUpload, mediaItem.mediaId]);

    const handleVisibilityDetailsSubmit = React.useCallback(
        (status: PublishStatus) => {
            setVisibilityStatus(status);
            const totalUploadsCount = filesToUpload.reduce((count, obj) => {
                count += obj.files.length;
                return count;
            }, 0);
            console.log("Total upload files count: ", totalUploadsCount);
            // Start uploading all files
            setAllUploadStarted(true);
            if (totalUploadsCount > 0) {
                uploadFilesToS3();
            }
        },
        [filesToUpload, uploadFilesToS3, setAllUploadStarted],
    );

    const editFilmFestivalMediaItem = React.useCallback(async () => {
        const filmFestivalRequestDto = buildFilmFestivalUploadRequestDto(
            {
                details: details,
                languageDetails: languageDetails,
                castDetails: castDetails || [],
                sponsorsDetails: sponsorDetails || [],
                visibilityStatus: visibilityStatus,
            },
            staticFilePaths,
            mediaItem.mediaId,
            true,
        );
        if (!filmFestivalRequestDto) return;

        const finalDto = {
            ...filmFestivalRequestDto,
            video: undefined,
            category: undefined,
            ...(filmFestivalRequestDto.thumbnails?.userUploaded
                ? { thumbnails: filmFestivalRequestDto.thumbnails }
                : { thumbnails: undefined }),
            ...((filmFestivalRequestDto.subtitles?.length || 0) > 0
                ? {
                      subtitles: filmFestivalRequestDto.subtitles,
                  }
                : { subtitles: undefined }),
        };

        if (filmFestivalRequestDto) {
            await vodService.editFilmFestivalMediaItem(finalDto);
        }
        onUploadComplete();
    }, [
        details,
        languageDetails,
        castDetails,
        sponsorDetails,
        visibilityStatus,
        staticFilePaths,
        mediaItem.mediaId,
        onUploadComplete,
    ]);

    React.useEffect(() => {
        if (!allUploadStarted) return;
        const uploadedCount = staticFilePaths.reduce((count, obj) => {
            count += obj.paths.length;
            return count;
        }, 0);

        const actualCountToUpload = filesToUpload.reduce((count, obj) => {
            count += obj.files.length;
            return count;
        }, 0);

        if (uploadedCount === actualCountToUpload) {
            editFilmFestivalMediaItem();
            setAllUploadStarted(false);
        }

        // send to dynamo db after all files are uploaded
        console.log("Static uploaded paths count: ", uploadedCount);
        console.log("Static uploaded paths count: ", staticFilePaths);

        console.log("actual files to upload count: ", actualCountToUpload);
        console.log("Actual Files to upload: ", filesToUpload);
    }, [
        staticFilePaths,
        filesToUpload,
        languageDetails,
        details,
        castDetails,
        visibilityStatus,
        sponsorDetails,
        editFilmFestivalMediaItem,
        allUploadStarted,
    ]);

    const body: { [key in ContentTypes]: React.ReactNode } =
        React.useMemo(() => {
            return {
                [ContentTypes.UPLOAD_DETAILS]: (
                    <DetailsSection
                        onDetailsSubmit={handleDetailsSubmit}
                        details={details}
                        plans={plans}
                    />
                ),
                [ContentTypes.UPLOAD_LANGUAGE_CC]: (
                    <LanguageDetails
                        onLanguageDetailsSubmit={handleLanguageDetailsSubmit}
                        languageDetails={languageDetails}
                    />
                ),
                [ContentTypes.UPLOAD_CAST]: (
                    <CastDetails
                        onCastDetailsSubmit={handleCastDetailsSubmit}
                        castDetails={castDetails}
                    />
                ),
                [ContentTypes.UPLOAD_SPONSORS]: (
                    <SponsorDetails
                        onSponsorsDetailsSubmit={handleSponsorDetailsSubmit}
                        sponsorDetails={sponsorDetails}
                    />
                ),
                [ContentTypes.UPLOAD_VISIBILITY]: (
                    <VisibilityDetails
                        percentage={percentage}
                        visibilityStatus={visibilityStatus}
                        onVisibilityDetailSubmit={handleVisibilityDetailsSubmit}
                    />
                ),
            };
        }, [
            castDetails,
            details,
            handleCastDetailsSubmit,
            handleDetailsSubmit,
            visibilityStatus,
            handleLanguageDetailsSubmit,
            handleSponsorDetailsSubmit,
            handleVisibilityDetailsSubmit,
            languageDetails,
            percentage,
            sponsorDetails,
            plans,
        ]);

    return (
        <Styled.UploadBodyWrapper>
            <Styled.UploadBodyWrapperRow>
                <Styled.UploadFormWrapper lg={4}>
                    {body[contentType]}
                </Styled.UploadFormWrapper>
                <Styled.UploadPreviewWrapper lg={8}>
                    <UploadPreview uploadedFile={mediaItem.playbackUrl} />
                </Styled.UploadPreviewWrapper>
            </Styled.UploadBodyWrapperRow>
        </Styled.UploadBodyWrapper>
    );
};
