import { Container, Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { colors, types } from "../../../libs";

export const ManageAccessTokenWrapper = styled(Container)`
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Header = styled.h2`
    ${types.headers.secondary};
    background: ${colors.text.textGradient};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
`;

export const StyledTable = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .rdt_TableCell,
    .rdt_TableCol_Sortable {
        font-size: 16px;
    }
`;

export const StyledTableOverlay = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${colors.background.green};
    & > .spinner-border {
        width: 5rem;
        height: 5rem;
    }
`;

export const TrashIcon = styled.i`
    font-size: 18px;
    cursor: pointer;
`;

export const DropdownMenu = styled(Dropdown.Menu)`
    border: 1px solid #fff;
`;

export const ModalWrapper = styled.div`
    background-color: ${colors.background.lightBlack};
    padding: 20px;
`;

export const ModalTitle = styled.div`
    ${types.body.secondaryTitle}
    color: ${colors.text.white};
    font-weight: bold;
`;

export const ModalSubtitle = styled.div`
    ${types.body.normal}
`;

export const ModalTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TrialIDWrapper = styled.div`
    display: flex;
    align-items: center;
    &:hover {
        cursor: pointer;
        color: ${colors.background.green};
        text-decoration: underline;
    }
`;

export const CodeOutput = styled.pre``;
