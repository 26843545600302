import * as React from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import {
  AccountForm,
  ConnectWithStripe,
  MemberPlanForm,
  PaymentAccountDetails,
  PlanDetails,
  StripeWrapper,
} from "../../../components";
import {
  IMemberPlan,
  IMemberPlanRequest,
  IPaymentAccount,
  IPlanType,
  ILiveChannel,
  ConfirmModal,
  ROUTEPATHS,
} from "../../../libs";
import { paymentService } from "../../../services";
import { liveChannelService } from "../../../services";

import * as Styled from "./ManagePaymentsContainer.styled";

export const ManagePaymentsContainer: React.FC = () => {
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = React.useState(true);
  const [accountData, setAccountData] = React.useState<IPaymentAccount>();
  const [productPlans, setProductPlans] = React.useState<IMemberPlan[]>([]);
  const [showCreatePlanForm, setShowCreatePlanForm] = React.useState(false);
  const [showUpdateAccountForm, setShowUpdateAccountForm] =
    React.useState(false);

  const [planToEdit, setPlanToEdit] = React.useState<IMemberPlan | undefined>(
    undefined
  );
  const [channelsList, setChannelsList] = React.useState<ILiveChannel[]>([]);

  const getStripeAccountDetails = React.useCallback(async () => {
    setShowLoader(true);
    try {
      const response = await paymentService.getStripeCustomerAccountDetails();

      setAccountData(response.data?.data);
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  }, []);

  const getCustomerProductPlans = React.useCallback(async () => {
    setShowLoader(true);
    try {
      const response = await paymentService.getCustomerProductPlans();
      setProductPlans(response);
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoader(false);
    }
  }, []);

  const saveCustomerAccountDetails = React.useCallback(
    async (accountId: string) => {
      setShowLoader(true);
      try {
        await paymentService.saveStripeCustomerAccountDetails(accountId);
        navigate(ROUTEPATHS.MANAGE_PAYMENTS_PLANS, { replace: true });
      } catch (err) {
        console.log(err);
      } finally {
        setShowLoader(false);
      }
    },
    [navigate]
  );

  React.useEffect(() => {
    const getLiveChannels = async () => {
      const response = await liveChannelService.getAllLiveChannels();
      setChannelsList(response);
    };
    getLiveChannels();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const accountId = search.get("accountId")
      ? search.get("accountId")
      : undefined;
    if (accountId) {
      saveCustomerAccountDetails(accountId);
    } else {
      getStripeAccountDetails();
    }
  }, [search, saveCustomerAccountDetails, getStripeAccountDetails]);

  React.useEffect(() => {
    if (accountData) {
      getCustomerProductPlans();
    }
  }, [accountData, getCustomerProductPlans]);

  const initialValues: IMemberPlanRequest = {
    name: planToEdit?.name || "",
    amount: planToEdit?.amount || 0,
    description: planToEdit?.description || "",
    expiration: planToEdit?.expiration || "",
    isEnabled: planToEdit?.isEnabled ?? true,
    planType: planToEdit?.planType || IPlanType.VOD,
    isPublic: planToEdit?.isPublic ?? true,
    isRecurring: planToEdit?.isRecurring ?? false,
    frequency: planToEdit?.frequency || "",
  };

  const handleCreatePlanCancel = React.useCallback(() => {
    setShowCreatePlanForm(false);
    setPlanToEdit(undefined);
  }, []);

  const handleCreatePlanSubmit = React.useCallback(
    async (formData: IMemberPlanRequest, setSubmitting) => {
      setSubmitting(true);
      try {
        const response = await paymentService.createCustomerProductPlan(
          formData
        );
        console.log(response);
        await getCustomerProductPlans();
      } catch (err) {
        console.log(err);
      } finally {
        setSubmitting(false);
        setShowCreatePlanForm(false);
      }
    },
    [getCustomerProductPlans]
  );

  const handleEditPlanSubmit = React.useCallback(
    async (formData: IMemberPlanRequest, setSubmitting) => {
      setSubmitting(true);
      try {
        const response = await paymentService.updateCustomerProductPlan(
          planToEdit?.id!,
          formData
        );
        console.log(response);
        await getCustomerProductPlans();
      } catch (err) {
        console.log(err);
      } finally {
        setSubmitting(false);
        setShowCreatePlanForm(false);
      }
    },
    [getCustomerProductPlans, planToEdit?.id]
  );

  const handlePlanDelete = React.useCallback(
    async (planId: string) => {
      if (channelsList.some((item) => item.planId.includes(planId))) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ConfirmModal
                title="Attention!"
                description={[
                  {
                    text: `This plan is associated with ${channelsList.reduce((a, v) => { if (v.planId.includes(planId)) { return (a + 1) } else { return (a) } }, 0)} channel(s).`,
                    bold: true,
                  },
                  {
                    text: "Please unassociate the payment plan before deleting.",
                    bold: false,
                  },
                ]}
                noText="Cancel"
                yesText="Go To Channels"
                onNo={() => onClose()}
                onYes={() => { navigate(`${ROUTEPATHS.LIVE_CHANNELS}`, { replace: true }); onClose() }}
              ></ConfirmModal>
            );
          },
        });

        return;
      }

      setShowLoader(true);
      try {
        const response = await paymentService.deleteCustomerProductPlan(planId);
        console.log(response);
        await getCustomerProductPlans();
      } catch (err) {
        console.log(err);
      } finally {
        setShowLoader(false);
      }
    },
    [getCustomerProductPlans, channelsList, navigate]
  );

  const handleUpdateAccountSubmit = React.useCallback(
    async (
      bankAccountToken: string,
      setSubmitting: (result: boolean) => void
    ) => {
      if (accountData) {
        setSubmitting(true);
        try {
          await paymentService.updateStripeCustomerAccount(
            accountData?.accountId,
            bankAccountToken
          );
          setShowUpdateAccountForm(false);
          await getStripeAccountDetails();
        } catch (err) {
          console.log(err);
        } finally {
          setSubmitting(false);
        }
      }
    },
    [accountData, getStripeAccountDetails]
  );

  const handlePlanEdit = React.useCallback(
    (planId) => {
      setPlanToEdit(productPlans.find((item) => item.id === planId));
      setShowCreatePlanForm(true);
    },
    [productPlans]
  );

  const handleUpdateAccountClick = React.useCallback(() => {
    setShowUpdateAccountForm(true);
  }, []);

  if (showLoader) {
    return (
      <Styled.SpinnerWrapper>
        <Spinner animation="border">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Styled.SpinnerWrapper>
    );
  }

  return (
    <Styled.ManagePaymentsContainer fluid>
      <Styled.WelcomeHeaderWrapper>
        <Styled.WelcomeHeader>Payments and Plans</Styled.WelcomeHeader>
      </Styled.WelcomeHeaderWrapper>
      {showCreatePlanForm ? (
        <>
          <Styled.SectionTitle>
            {planToEdit ? "Edit Plan" : "Create plan"}
          </Styled.SectionTitle>
          <MemberPlanForm
            initialValues={initialValues}
            showRecurring={!planToEdit}
            onSubmit={
              planToEdit ? handleEditPlanSubmit : handleCreatePlanSubmit
            }
            onCancel={handleCreatePlanCancel}
          />
        </>
      ) : showUpdateAccountForm && accountData ? (
        <StripeWrapper accountId={accountData.accountId}>
          <Styled.SectionTitle>Update Account</Styled.SectionTitle>
          <AccountForm
            onSubmit={handleUpdateAccountSubmit}
            onCancel={() => setShowUpdateAccountForm(false)}
          />
        </StripeWrapper>
      ) : accountData ? (
        <>
          <PaymentAccountDetails
            accountDetails={accountData}
            onUpdateAccountClick={handleUpdateAccountClick}
          />
          {accountData.enabled && (
            <PlanDetails
              plans={productPlans}
              onCreatePlanClick={() => setShowCreatePlanForm(true)}
              onEditPlanClick={handlePlanEdit}
              onDeletePlanClick={handlePlanDelete}
            />
          )}
        </>
      ) : (
        <ConnectWithStripe />
      )}
    </Styled.ManagePaymentsContainer>
  );
};
