import { ModalProps } from "react-bootstrap";
import {
    BaseButton,
    Error,
    IFreeTrialAccessTokenRequest,
    Switch,
    TextAreaInput,
    TextInput,
} from "../../../../libs";
import * as Styled from "./CreateAccessTokenModal.styled";
import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { paymentService } from "../../../../services";
import Select from "react-select";
import * as Yup from "yup";

interface IProps extends ModalProps {
    planOptions: { value: string; label: string }[];
}

export const CreateAccessTokenModal: React.FC<IProps> = ({
    planOptions,
    ...props
}) => {
    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            expiredDate: new Date(),
            isFullAccess: false,
            isEnabled: true,
            planIds: [] as string[],
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("* Required"),
            description: Yup.string().required("* Required"),
            expiredDate: Yup.date()
                .required("* Required")
                .min(new Date(), "* Expired date must be in the future"),
            isFullAccess: Yup.boolean().default(false),
            planIds: Yup.array()
                .of(Yup.string())
                .when("isFullAccess", {
                    is: false,
                    then: (schema) =>
                        schema.min(
                            1,
                            "* At least one plan ID is required when full access is false",
                        ),
                    otherwise: (schema) => schema,
                }),
        }),
        onSubmit: async (values) => {
            try {
                console.log("Submitted values:", values);
                const requestDto: IFreeTrialAccessTokenRequest = {
                    name: values.name,
                    description: values.description,
                    expiredDate: values.expiredDate.toISOString(),
                    isFullAccess: values.isFullAccess,
                    planId: values.planIds,
                    isEnabled: values.isEnabled,
                };
                await paymentService.createFreeTrialAccessToken(requestDto);

                toast.success("Free trial successfully created");
                props.onHide?.();
            } catch (err: any) {
                console.error("Error creating free trial:", err);
                toast.error("There was an error while creating the free trial");
            }
        },
    });

    const handlePlanSelect = React.useCallback(
        (option: any) => {
            formik.setFieldError("planIds", "");
            if (option) {
                const selectedPlanIds = option.map((el: any) => el.value);
                formik.setFieldValue("planIds", selectedPlanIds);
            } else {
                formik.setFieldValue("planIds", undefined);
            }
        },
        [formik],
    );

    return (
        <Styled.GenerateModal
            {...props}
            size="lg"
            dialogClassName="modal-width"
            keyboard={false}
        >
            <Styled.ModalWrapper>
                <Styled.ModalTitle>Create Free Trial</Styled.ModalTitle>
                <Styled.Spacer />
                {formik.touched.name && formik.errors.name && (
                    <Error message={formik.errors.name} />
                )}
                <TextInput
                    fieldValue={formik.values.name}
                    fieldName="name"
                    fieldLabel="Name *"
                    placeholder="Enter free trial name"
                    onChangeHandler={formik.handleChange}
                    hasError={
                        formik.touched.name && Boolean(formik.errors.name)
                    }
                />
                <Styled.Spacer />
                {formik.touched.description && formik.errors.description && (
                    <Error message={formik.errors.description} />
                )}
                <TextAreaInput
                    fieldValue={formik.values.description}
                    fieldName="description"
                    fieldLabel="Description *"
                    placeholder="Enter description"
                    onChangeHandler={formik.handleChange}
                    hasError={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                    }
                />
                <Styled.Spacer />
                {formik.touched.expiredDate && formik.errors.expiredDate && (
                    <Error message={String(formik.errors.expiredDate)} />
                )}
                <Styled.DatePickerWrapper>
                    <Styled.DatePickerLabel>
                        Expiry Date *
                    </Styled.DatePickerLabel>

                    <Styled.CustomDatePicker
                        selected={formik.values.expiredDate}
                        onChange={(date: Date) => {
                            formik.setFieldValue("expiredDate", date);
                        }}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select date"
                    />
                </Styled.DatePickerWrapper>

                <Styled.Spacer />
                {formik.touched.planIds && formik.errors.planIds && (
                    <Error
                        message={
                            Array.isArray(formik.errors.planIds)
                                ? formik.errors.planIds.join(", ")
                                : formik.errors.planIds
                        }
                    />
                )}

                <Select
                    className="custom-select"
                    isMulti
                    styles={{
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            background: "#1E2025",
                            color: "#fff",
                        }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            background: state.isFocused ? "#ccc" : "#1E2025",
                            color: state.isFocused ? "#1E2025" : "#fff",
                        }),
                        singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            color: "#fff",
                        }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: "50px",
                            background: "#1E2025",
                            color: "#fff",
                            border:
                                formik.errors.planIds && formik.touched.planIds
                                    ? "1px solid red"
                                    : state.isFocused
                                      ? "1px solid transparent"
                                      : "1px solid transparent",
                        }),
                    }}
                    placeholder="Select Payment Plan"
                    defaultValue={planOptions.filter((el) =>
                        formik.values.planIds?.includes(el.value),
                    )}
                    isClearable
                    options={planOptions}
                    onChange={handlePlanSelect}
                />
                <Styled.Spacer />
                <Styled.SwitchWrapper>
                    <Styled.SwitchLabel>Is Full Access *</Styled.SwitchLabel>
                    <Switch
                        checked={formik.values.isFullAccess}
                        onChange={(event) =>
                            formik.setFieldValue(
                                "isFullAccess",
                                event.target.checked,
                            )
                        }
                    />
                </Styled.SwitchWrapper>
                <Styled.Spacer />
                <Styled.SwitchWrapper>
                    <Styled.SwitchLabel>Is Enabled *</Styled.SwitchLabel>
                    <Switch
                        checked={formik.values.isEnabled}
                        onChange={(event) =>
                            formik.setFieldValue(
                                "isEnabled",
                                event.target.checked,
                            )
                        }
                    />
                </Styled.SwitchWrapper>
                <Styled.Spacer />
                <BaseButton onClick={formik.handleSubmit}>
                    Create Free Trial
                </BaseButton>
            </Styled.ModalWrapper>
        </Styled.GenerateModal>
    );
};
